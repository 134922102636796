import Http from '@/shared/http-config'

export default {
  name: "FacebookFuncs",
  data: function () {
    return {
    }
  },
  created() {
  },
  methods: {
    getLinked(post){
      return new Promise((resolve, reject) => {
        if(post.meta && post.meta.id > 0){
          Http.request('GET', '/social/meta/' + post.meta.id).then(function (posts) {
            resolve({ type: 'meta', data: { id: post.meta.id, posts: posts.data.posts }});
          });
        }
        else if(post.news && post.news.id > 0){
          Http.request('GET', '/news/' + post.news.id).then(function (posts) {
            resolve({ type: 'news', data: { id: post.news.id, posts: posts.data.posts }});
          });
        }
        else if(post.course && post.course.id > 0){
          Http.request('GET', '/courses/' + post.course.id).then(function (posts) {
            resolve({ type: 'course', data: { id: post.course.id, posts: posts.data.posts }});
          });
        }
        else if(post.guestbook && post.guestbook.id > 0){
          Http.request('GET', '/guestbook/' + post.guestbook.id).then(function (posts) {
            resolve({ type: 'guestbook', data: { id: post.guestbook.id, posts: posts.data.posts }});
          });
        }
        else {
          resolve({ data: {posts: [post] }});
        }
      });
    },
    getLinkedPosts(post){
      if(post.meta && post.meta.id > 0 && post.meta.posts){
        return post.meta.posts;
      }
      else if(post.news && post.news.id > 0 && post.news.posts){
        return post.news.posts;
      }
      else if(post.course && post.course.id > 0 && post.course.posts){
        return post.course.posts;
      }
      else if(post.guestbook && post.guestbook.id > 0 && post.guestbook.posts){
        return post.guestbook.posts;
      }
      else {
        return [post];
      }
    },
  }
}
