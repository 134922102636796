<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import SocialPostForm from '@/views/marketing/media-manager/details/Form.vue'
import functions from '@/views/marketing/media-manager/functions'

export default {
  mixins: [functions],
  components: {SocialPostForm},
  name: "Details",
  data: function () {
    return {
      linkable: {data: {posts: []}},
      copyPosts: []
    }
  },
  created: function(){
    let self = this;
    if(self.$route.params.post_id > 0){
      Http.request('GET', '/social/posts/' + self.$route.params.post_id).then((post) => {
        self.getLinked(post.data).then(function (linkable){
          self.linkable = linkable;
        })
      });
    }
    else if(self.$route.params.copy_post_id > 0){
      Http.request('GET', '/social/posts/' + self.$route.params.copy_post_id).then((post) => {
        self.getLinked(post.data).then(function (linkable){
          self.copyPosts = linkable.data.posts;
        })
      });
    }
  },
  methods: {
    save(){
      let self = this;
      self.$refs.socialPosts.store().then(function (){
        self.$router.push('/marketing/media-manager');
      });
    }
  }
}
</script>

<style scoped></style>
